<script setup lang="ts">
import { NavigationItem, UserVerificationStatus, UserRole } from '@/interfaces';

// import { navigationList } from '@/utils/data/navigation';
const { t } = useI18n();

const navigationList = computed<NavigationItem[]>(() => [
  { path: Page.CATALOG, name: t('components.common.header.catalog') },
  { path: Page.TRANSACTION, name: t('components.common.header.transactions') },
  { path: Page.OPERATION, name: t('components.common.header.operationsProducts') },
  { path: Page.ASSORTMENT, name: t('components.common.header.assortment') },
  // { path: Page.OPERATIONS_HISTORY, name: t('components.common.header.operationsHistory') },
  // { path: Page.ORDER, name: t('components.common.header.transfers') },
  // { path: Page.PLANTATION, name: t('components.common.header.exchange') },
  // { path: Page.PLANTATION, name: t('components.common.header.auction') },
]);

const userStore = useUserStore();
const isApproved = computed(
  () => userStore.getVerificationStatus === UserVerificationStatus.APPROVED,
);
const isAdmin = computed(
  () => userStore.getRole === UserRole.ADMIN,
);

const checkAdmin = (item: NavigationItem) => {
  if (item.path === (Page.ASSORTMENT || Page.OPERATION ) && !isAdmin) {
    return false
  }
  return true
}

</script>

<template>
  <nav>
    <ul class="flex flex-ic">
      <common-page-header-navigation-item v-for="(item, index) of navigationList" :key="index" :item="item"
        :is-approved="isApproved && checkAdmin(item)"></common-page-header-navigation-item>
    </ul>
  </nav>
</template>

<style scoped lang="scss">
nav {
  flex: 1 1 auto;

  ul {
    @media screen and (max-width: $md) {
      flex-direction: column;
      align-items: end;
      color: black;
      font-size: 24px;
    }

    @apply gap-6;
  }
}
</style>
